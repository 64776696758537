@import 'colors';
@import 'mixin';

label {
  @extend .display-flex;
  @extend .flex-direction-column;
  text-transform: uppercase;
  color: $my-tan;
  font-weight: 700;
  input,
  textarea,
  select {
    margin-top: 10px;
  }
  &:not(:first-of-type) {
    margin-top: 20px;
  }
}

.lnk-gen {
  color: $my-tan;
  font-weight: 700;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
