@import 'colors';
@import 'mixin';

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: url('../assets/bkg.jpg') no-repeat center center fixed;
  background-size: cover;
  color: $white;
}

body,
div,
input,
select,
textarea,
a,
button,
pre {
  font-size: 15px;
  font-family: 'Orbitron', sans-serif;
  vertical-align: top;
  font-weight: 400;
  color: $white;
  border: 0;
  outline: none;
}
textarea {
  resize: none;
}

a,
button {
  @extend .trstn;
  &:hover {
    @extend .trstn;
  }
}

::selection {
  background-color: $my-red;
  color: $white;
}

::-moz-selection {
  background-color: $my-red;
  color: $white;
}
